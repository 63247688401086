.App {
  /* text-align: center; */
  text-decoration: #282c34;
  /* padding: 2px; */
  /* height: "10px"; */
  background-color: #ffffff;
  overflow-y: hidden;
}
.color_blue{
  color:  rgb(0, 197, 205);
}
.color_danger{
  color: #FF5733;
}
.color_warning{
  color: #E4A11B;
  /* goldenrod */
}
.color_secondary{
  color: #282c34;
}
.color_success{
  color: #14A44D;
}
.font_sm{
  font-size: .9rem;
}
.font_lg{
  font-size: 1.5rem;
}
.font_sx{
  font-size: .5rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.makeStyles-content-1{
  /* background-color: #fff !important; */
  height: 100vh !important;
}
.p-input-icon-right > .p-inputtext {
  padding-right: 5.2rem !important;
}

.datatable .p-datatable .p-datatable-header {
  background: none !important;
  color: #343a40;
  border: none !important;
  /* border-width: 1px 0 1px 0; */
  padding: 0 !important;
  font-weight: 700;
}

/* .p-datatable-thead > tr {
  background: linear-gradient(199deg, rgba(255, 255, 255, 1) 0%, rgba(21, 171, 112, 1) 90%) !important;
} */
.table-header-action .p-inputtext{
  width: 150px;
  height: 35px;
}
.table-header-action .table-button{
  width: 150px;
  height: 35px;
  border: 1px solid rgb(0, 197, 205) !important;;
  background-color: rgb(0, 197, 205) !important;
  justify-content: center;
  color: #000;
      /* float: 'right', */
      /* color: #FFF, */
      /* marginLeft: 25px", */
      /* width: "250px", */
      /* height: '45px', */
      
}
.table-header-action .table-button-danger{
  width: 130px;
  height: 35px;
  background-color: #FF5733 !important;
  justify-content: center
}
.DataTable{
  display: flex;
  justify-content: space-between;
}
.datatable .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr{
  background-color: rgb(21 171 112 / 63%) !important;
}
.table-header-action{
  display: flex; 
  flex-direction: row;
  justify-content:space-evenly
}
.p-dialog-draggable .p-dialog-header{
  cursor: auto !important;
}
@media only screen and (max-width: 480px) {
  .table-header-action{
    display: flex;
    text-align: end;
    margin: 0;
  }
  .DataTable{
    display: block;
  }
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: transparent !important;
  transition: box-shadow 0.2s;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #EEF2FF;
  color: #fff !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #fff !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 0.5% 1.5% !important;
  padding-left: 1rem !important;
  /* justify-content: center; */
  /* display: flex; */
}

.sidebarClass {
  background: linear-gradient(199deg, rgba(255, 255, 255, 1) 0%, rgba(21, 171, 112, 1) 90%);
  border-right: 'none';
  color: black;
  margin-top: "60px";
  z-index: '1000';
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
  /* text-align: center; */
}

.qunty .p-inputnumber-input {
  flex: 1 1 auto;
  width: 75px !important;
}
.p-button{
  background: #338D7e !important;
  border: 1px solid #338D7e !important;
}
.status_button{

}
.status_button .primary{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid rgb(0, 197, 205) !important;
  color: rgb(0, 197, 205);
  background-color: transparent !important;
  box-shadow: none;
  width: 130px;
}
.status_button .success{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #14A44D !important;
  color: #14A44D;
  background-color: transparent !important;
  box-shadow: none;
  width: 130px;
}
.status_button .secondary{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #282c34 !important;
  color: #282c34;
  background-color: transparent !important;
  box-shadow: none;
  width: 130px;
}
.status_button .warning{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #E4A11B !important;
  color: #E4A11B;
  background-color: transparent !important;
  box-shadow: none;
  width: 130px;
}
.status_button .danger{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #FF5733 !important;
  color: #FF5733;
  background-color: transparent !important;
  box-shadow: none;
  width: 130px;
}
.status_button .contained_primary{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid rgb(0, 197, 205) !important;
  color: #fff;
  background-color: rgb(0, 197, 205) !important;
  box-shadow: none;
  /* width: 150px; */
}
.status_button .contained_success{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #14A44D !important;
  color: #fff;
  background-color: #14A44D !important;
  box-shadow: none;
  /* width: 150px; */
}
.status_button .contained_warning{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #E4A11B !important;
  color: #fff;
  background-color: #E4A11B !important;
  box-shadow: none;
  /* width: 150px; */
}
.status_button .contained_danger{
  font-size: .7rem;
  font-weight: 700;
  border: 1px solid #FF5733 !important;
  color: #fff;
  background-color: #FF5733 !important;
  box-shadow: none;
  /* width: 150px; */
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  color: #000 !important;
}
.Footer_Submit_Button{
  position: sticky;
  bottom: -25px;
}
.w130{
  width: 130px;
}
.minw170{
  min-width: 170px;
}
.max-w-80{
  max-width: 80px;
}
.sub_heading{
  font-size: .9rem;
  font-weight: 700;
  margin-right: 10px;
}
.sub_heading_1{
  font-size: 1rem;
  font-weight: 700;
  margin-right: 10px;
}
.heading_dialog .p-dialog-title{
  overflow: auto;
}
.float-left{
  float: left;
}
.pl-1{
  padding-left: .25rem;
}
.pr-1{
  padding-right: .25rem;
}
.p-checkbox .p-checkbox-box.p-highlight{
  border-color: #338D7E !important;
  background-color: #338D7E !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
  border-color: #338D7E !important;
}
.p-inputtext:enabled:hover{
  border: 1px solid #338D7E !important;
}
.p-inputtext:enabled:focus{
  border-color:#338D7E !important;
  box-shadow: 0 0 0 0.2rem #338d7e63 !important;
}
.pi{
  font-size: .8rem !important;
}
/* light-green-color - rgb(21 171 112 / 63%) */

.multiselect-demo .p-multiselect {
  min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
  width: 17px;
}

.p-multiselect-label-container {
  overflow: hidden;
  flex: 1 1 auto;
  cursor: pointer;
  padding: 10px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.p-multiselect {
  height: 35px !important;
}
.text_wrap_180{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 180px;
}
.text_wrap_250{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 250px;
}
.fix { 
  position: 'sticky';
  right: 0;
  padding: '11px 16px';
  box-shadow: '0px 4px 4px 0px #999';
 }
 .fixColumn {        
  position: 'sticky' !important; 
  right: 0
 }
 .edit_icon{
  margin-right: .5rem;
  cursor: pointer;
 }
 .edit_icon:hover {
  color: #338D7E;
 }
 .edit_icon_delete{
  margin-right: .5rem;
  cursor: pointer;
 }
 .edit_icon_delete:hover {
  color: #f03607;
 }
 .p-calendar-w-btn-right .p-datepicker-trigger{
  height: 35px !important;
 }
 .p-inputtext, .p-multiselect-label-container{
  height: 35px !important;
padding: 0.3rem 0.5rem !important;
 }
 .p-multiselect .p-multiselect-label{
padding: 0px !important;
transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

 }
 .p-dropdown:not(.p-disabled):hover, .p-multiselect:not(.p-disabled):hover{
  border: 1px solid #338D7E !important;
 }
 .p-dropdown:not(.p-disabled).p-focus, .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #338d7e63 !important
 }
 .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  color: #338D7E !important
 }
 .pr-6{
  padding-right: 6px !important;
 }
 .pr-0{
  padding-right: 0px !important;
 }
 .pl-1rem{
  padding-left: 1rem !important;
 }
 .p-multiselect-panel{
  width: 50%;
 }
 @media only screen and (max-width: 480px) {
  .pr-6 {
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 576px) {
  .p-multiselect-panel  {
    flex: 0 0 auto;
    width: 30% !important;
  }
}
.p-inputtextarea {
  height: auto !important;
}
.p-scrollpanel .p-scrollpanel-bar {
  background: #198754 !important;
  border: 0 none;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.3rem 1rem !important;
  color: #495057;
  border-radius: 0;
  font-weight: 500 !important;
  transition: box-shadow 0.2s;
  -webkit-user-select: none;
  user-select: none;
}