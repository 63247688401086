@font-face {
       font-family: 'FuturaLight';
      
     font-weight: '500';
   
       src: url('../../fonts/futura\ medium\ bt.ttf');
       
       }
   
   
@font-face {
       font-family: 'TableData';
     
       font-weight: '600';
   
       src: url('../../fonts/Futura\ Book\ font.ttf');
   
       }

.admin-users_card {
       background: linear-gradient(162deg, rgba(176,224,230,1) 0%, rgba(30,144,255,1) 100%) !important;
       color: #fff !important;
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       align-items: center;
       padding: 16px;
       border: 0px solid !important;
}

.store-managers_card {
       background: linear-gradient(162deg, rgba(255,228,181,1) 0%, rgba(255,165,0,1) 100%) !important;
       color: #fff !important; 
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       align-items: center;
       padding: 16px;
       border: 0px solid !important;
}

.end-users_card {
       background: linear-gradient(162deg, rgba(0,191,255,1) 0%, rgba(0,0,139,1) 100%) !important;
       color: #fff !important; 
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       align-items: center;
       padding: 16px;
       border: 0px solid !important;
}

.products_card {
       background: linear-gradient(162deg, rgba(0,255,127,1) 0%, rgba(0,100,0,1) 100%) !important;
       color: #fff !important;
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       align-items: center;
       padding: 16px;
       border: 0px solid !important;
}

.total-revenue_card {
       background: linear-gradient(162deg, rgba(255,215,0,0.7399334733893557) 0%, rgba(255,185,0,1) 100%) !important;
       color: #fff !important;
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       align-items: center;
       border: 0px solid !important;
}

.card_height {
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       cursor: default !important;
}

.p-scrollpanel-content {
    height: calc(100% + 20px) !important;
    width: calc(100% + 20px) !important;
    padding: 0px !important;
    position: relative;
    overflow: scroll;
    box-sizing: border-box;
}

.chart_card {
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       height: 67vh !important;
       width: 99% !important;
}

.chart_card2 {
       box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%) !important;
       height: 67vh !important;
       width: 99% !important;
}

@media only screen and (min-width: 300px) {
       .chart_card {
              height: 60vh;
       }

       .chart_card2 {
              height: 48vh;
       }
}

@media (min-width: 768px){
       .col-md-2 {
              flex: 0 0 auto;
              width: 20% !important;
       }
}

/* .popular-products_card {
       font-size: 17px;
       font-weight: 600;
       background: linear-gradient(162deg, rgba(255,213,128,1) 0%, rgba(255,0,0,1) 100%);
       border-top-left-radius: 5px;
       border-top-right-radius: 5px;
       color: #fff;
} */