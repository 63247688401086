.productspage {
    background-color:lightcyan;
}

.kiHXWb {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 200px !important;
    max-width: 400px !important;
    height: 75px !important;
    border: dashed 1px #ccc !important;
    padding: 8px 16px 8px 8px;
    border-radius: 4px !important;
    cursor: pointer;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 5px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
.product_cancel_icon{
    position: absolute;
    top: 5px;
    right: 5px;
    border: 1px solid red;
    background-color: red;
    border-radius: 25px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: '600'; 
    cursor: pointer;
    /* width: 100px; */
}
.product_view_image{
    width: 100%;
    height: 148px;
    border-radius: 15px;
}
.product_image{
    width: 100%;
    height: 150px;
    position: relative; 
    text-align: center;
    border: 1px solid #ccc; 
    border-radius: 15px;
}
.backIcon {
    font-size: 25px;
    font-weight: 400;
}
.backIcon:hover{
    color: red;
}