.images {
  padding-left: 3rem;
  padding-right: 3rem;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 7%; 
  height: 100vh;
  background-color: #2E8B57;
  opacity: 0.85;

}

.login-bg {
  background-image: url('../../images/bg1.png') !important;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: cover;
  width: 99.8vw !important;
  height: 100vh !important; 
  opacity: 0.5;
  position: fixed;
}

.form-control {
  display: block;
  width: 400px;
  margin-left: 150px;
  margin-top: 50px;
  /* margin-bottom: 20px; */
  height: auto !important;
  line-height: 2.5;
  color: #494e57;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #338D7E;
  border-radius: .25rem;
  opacity: 1.5;
}

.input-md {
  height: calc(2.3rem + 1px);
  padding: .375rem .75rem;
  font-size: .875rem;
}

.logo {
  /* width: 33rem; */
  height: 110px;
  background-size: auto;
  /* display: inline-block;
  border-radius: 15px; */
}

.star {
  width: 100%;
  margin-top: 12px;
}

@font-face {
  font-family: 'Futura';
  src: url('../../fonts/futura\ medium\ bt.ttf');
}

.login-body {
  font-family: 'Futura';
}

.paper {
  border: 4px solid #030303;
  width: 40vw;
  border-radius: 20px !important;
  /* margin-left: 400px;
  margin-right: 400px;
  margin-top: 100px;
  margin-bottom: 200px; */
}

.submitButton {
  display: "inline-block";
  margin-top: "25px";
  margin-left: "20px";
  color: "#fff";
  width: "435px";
  height: "50px";
  border-radius: "5px";
  border: "2px solid #303030";
}

.login-card {
  border-radius: 1rem !important;
  /* background-image: url('../../images/logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
  z-index: 20 !important;
  opacity: 1 !important;
  background-color: #F0F8FF !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.login_img {
  /* background-image: url('../../images/login-img.jpg'); */
  background-position: center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 480px) {
  .login_img {
    background-color: none;
    display: none;
  }
}

.p-inputtext-login {
  border-radius: 5px !important;
  /* border: 1px solid #338D7E !important; */
  width: 280px !important; 
  height: 40px !important;
  /* padding-right: '6.15rem'; */
}
/* .p-inputtext{
  border: none !important;
} */
.p-username-login {
  border: 1px solid #338D7E !important;

}
.p-username-login:focus {
  box-shadow: 0 0 0 0.2rem #338d7e63 !important;
}
.p-password-login .p-inputtext {
  border: 1px solid #338D7E !important;
}
.p-password-login .p-inputtext:focus {
  box-shadow: 0 0 0 0.2rem #338d7e63 !important;
}