.spacing:hover {
    background-color: 'hsla(0,0%,100%,.1)' !important;
    color: #EDEADE;
}
#style-scroll .MuiListItem-root{
    color: #000000a6;
}

.spacing span {
    font-size: 14px;
    font-family: 'FuturaLight';
    font-weight: 500;
}

@font-face {
    font-family: 'FuturaLight';
    font-weight: '400';
    src: url('../../fonts/Futura\ Book\ font.ttf');
}

@font-face {
    font-family: 'FuturaLight';
    font-weight: '500';
    src: url('../../fonts/futura\ medium\ bt.ttf');
}

.p-sidebar .p-sidebar-header {
    padding: 0rem;
    display: none !important;
}

.p-sidebar .p-sidebar-content {
    padding: 0rem !important;
}
.MuiList-padding {
    padding-top: 1px !important;
    /* padding-bottom: 8px; */
}

.p-sidebar-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500 !important;
    align-items: center;
    pointer-events: none;
    background-color: transparent;
    transition-property: rgba(21,171,112,1) !important;
}
.drawerPaper.p-sidebar {
    background-color: rgba(21,171,112,1) !important;
    border-right: none;
    color: #414A4C !important;
    width: 230;
    margin-top: 2rem;
    padding-top: 2.5rem;
    z-index: 450;
}
.drawer {
    width: 230px;
    flex-shrink: 0;
}

.logo {
    width: 250px;
    height: 60px;
}
.icon {
    margin-right: 10px;
    width: 25px !important;
    height: 20px !important;
}
.fontSizing {
    font-size: 18px;
    font-weight: 800;
}
#style-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}

.p-listbox {
    background: rgba(21,171,112,1) !important;
    color: #006400 !important;
    border: 0px solid #ced4da !important;
    border-radius: 0px !important;
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #000000a6 !important;
    font-weight: 700 !important;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #ced4da !important;
    background: rgba(0,0,0,0.3) !important;
    border: 0px solid !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ced4da !important;
    background: rgba(0,0,0,0.3) !important;
    border: 0px solid !important;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem transparent !important;
}
.drawerPaper {
    background-color: rgba(21,171,112,1) !important;
    border-right: none;
    color: #414A4C !important;
    width: 230;
    margin-top: 8.5vh;
    z-index: 450;
}