.table-header-action .p-dropdown-panel .p-dropdown-header .p-dropdown-filter{
    height: 35px;
}

.custom_dropdown .p-inputtext{
    padding: .38rem;
}

.p-inputtext{
    height: 35px;
}

.custom_dropdown .p-dropdown-clearable span{
    margin-right: 1.75rem;
}

.setle.p-multiselect {
    height: 35px !important;
}

/* .p-multiselect .p-multiselect-label {
    padding: 0.25rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
} */

@media only screen and (max-width: 480px){
    .report_order_header .table-header-action {
        /* display: flex; */ 
        text-align: start;
        /* margin: 0; */
    }
}