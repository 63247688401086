.p-tabview-nav-content {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: contain auto;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: rgba(21,171,112,1) !important;
    color: rgba(21,171,112,1) !important;;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: rgba(21,171,112,1) !important;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem rgba(21,171,112,0.5) !important;
}