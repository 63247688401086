body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TableHead {
  background: rgb(0, 197, 205);
  /* //#00C5CD */
  background: linear-gradient(140deg, rgba(0, 197, 205, 1) 0%, rgb(183, 253, 254) 80%);
}

.Toolbar {
  background: rgb(0, 197, 205);
  /* //#00C5CD */
  background: linear-gradient(140deg, rgba(0, 197, 205, 1) 0%, rgb(183, 253, 254) 80%);
  border-bottom: 1px solid black;
}

.check {
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -2%;
  background: rgb(0, 197, 205);
  background: linear-gradient(140deg, rgba(0, 197, 205, 1) 0%, rgb(183, 253, 254) 80%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}