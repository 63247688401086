.container::-webkit-scrollbar {
    display: none;
}

.headerBody {
    background: rgb(0, 197, 205);
    /* //#00C5CD */
    background: linear-gradient(140deg, rgba(0, 197, 205, 1) 0%, rgb(183, 253, 254) 80%) !important;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.headerIcon {
    width: '250px';
    height: '73px';
    padding: '5px';
}

.containerone {
    height: 100vh;
    /* margin-top: -1%;
    margin-left: 7%; */
    padding-top: 3rem;
    overflow-y: auto;
    z-index: 1200 !important;
}

.containertwo {
    height: 100vh;
    /* margin-top: -1%; */
    /* padding-bottom: 4%; */
    padding-top: 3rem;
    overflow-y: auto;
    z-index: 1200 !important;
}

.content {
    flex-grow: 1;
    /* transition: , */
    margin-left: 240px;
    background-color: #e5e8f1;
    height: 100vh;
}
 
.contentShift {
    /* transition: ; */
    margin-left: 0px !important;
  }