@font-face {
    font-family: 'Date';
    font-weight: '600';
    src: url('../../fonts/Futura\ Book\ font.ttf');
}

.headerbody {
    /* #00C5CD */
    /* #338D7E */
    z-index: 501 !important;
    /* background: linear-gradient(199deg, rgba(255, 255, 255, 1) 0%, rgba(21, 171, 112, 1) 90%); */
    background-color: rgba(21,171,112,1);
    padding: 0 .5rem 0 .5rem !important;
    width: 100.5vw;
    height: 70px;
    margin-left: -0.5%;
    position: fixed;
}
.nav_username{
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
}
@media screen and (max-width: 480px) { 
    .nav_username{
        display: none;
    }
    .nav_username_in{
        display: block;
        font-size: 18px;
        font-weight: 500;
        /* color: ; */
    }
}
.dropdown-toggle::after {
    display: none !important;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle {
    white-space: nowrap;
    background-color: rgb(21,171,112) !important;
    border: 0px solid !important;
}