.ordSts.btn:hover {
    color: #000000 !important;
}

.text-name {
    max-lines: 1 !important;
    line-height: 15px !important;
    overflow: hidden;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    overflow: hidden !important;
}